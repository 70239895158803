import emptyImage from "assets/images/empty.png";
import school2 from "assets/images/school1.jpg";
import upload from "assets/images/upload.png";
import logo from "assets/images/logo.jfif";
import abujaLogo from "assets/images/abujaLogo.png";

export const appColor = "green";
export const appLight = "rgb(255, 245, 245)";
export const appLink = "#C3073F";
export const appDark = "#110000";
export const Grey1 = "rgb(150,150,150)";
export const empty = emptyImage;
export const uploader = upload;
export const appLogo = abujaLogo;
export const hott = school2;
export const instagramUrl = "";
export const twitterUrl = "";
export const facebookUrl = "";
export const stateCodeNumber = "FCT CODE";
export const brandShort = "SASS";
export const brandLong = "Smart Abuja Security Solutions";

export const CLOUDINARY = {
  UNSIGNED_UPLOAD_PRESET: "mediatek",
  BASE_URL: "https://api.cloudinary.com/v1_1",
  NAME: "mediatekk",
  FOLDERS: {
    IDENTITY_FILES: "identity-files",
  },
};

//#e89607
