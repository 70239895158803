import {
  AppBar,
  Box,
  Container,
  Hidden,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "styles/layout.css";
// import AuthMenuItem from "./configs/authMenuItem";
// import { FiUsers, FiHome } from "react-icons/fi"
import SiteBtn from "components/siteBtn";
import Brand from "components/brand";
import { useDashboardPath } from "hooks/useDashboardLinks";
// import MobileDrawer from "./configs/mobileDrawer";

export default function Navbar() {
  const navigate = useNavigate();
  const path = useDashboardPath();
  return (
    <>
      <Box style={{ backgroundColor: "white" }} sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="inherit" elevation={0}>
          <Container disableGutters={true} maxWidth="xl">
            <Toolbar
              style={{
                boxShadow: "0px 0.5px 3px rgb(210,210,210)",
                padding: "10px 4vw 10px 4vw",
                minHeight: "80px",
              }}
            >
              <Link to="/">
                <Brand />
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              <Hidden smDown>
                <Box
                  className="df ac"
                  sx={{ fontWeight: "700", width: "auto", mt: 1 }}
                >
                  <Box
                    sx={{ px: 2, cursor: "pointer" }}
                    onClick={() => navigate(path)}
                  >
                    Dashboard
                  </Box>
                </Box>
              </Hidden>
              <Box className="df">
                {/* here for school select */}
                <Hidden smDown>
                  <SiteBtn p={7} onClick={() => navigate("/login")}>
                    Login | Register
                  </SiteBtn>
                </Hidden>
                {/* <MobileDrawer /> */}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </>
  );
}
