import { appLink } from 'appConstants'
import React from 'react'
import { Link } from 'react-router-dom'
import { Span } from './typography'

function SiteLink(props) {
    const {sm , color, to} = props
  return (
    <Link to={to} {...props}><Span sm={sm} className="siteLink" color={color ?? appLink}>{props.children}</Span></Link>
  )
}

export default SiteLink