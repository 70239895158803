import { createAsyncThunk } from "@reduxjs/toolkit";
import { patchAsync } from "http/method";
import { getAsync } from "http/method";
import { postAsync } from "http/method";

export const createRoom = createAsyncThunk("create/room", async (data) => {
  await postAsync({ url: "rooms/upload", payload: data });
});

export const getRooms = createAsyncThunk("get/rooms", async (data) => {
  return await getAsync({ url: data?.url ?? "rooms", data });
});

export const checkIn = createAsyncThunk("check/in", async (data) => {
  await postAsync({ url: "customers/check_in", payload: data });
});

export const getManifest = createAsyncThunk("get/manifest", async (data) => {
  return await getAsync({ url: data?.url ?? "manifests", data });
});

export const getManifestDetails = createAsyncThunk(
  "get/manifestDetails",
  async (data) => {
    return await getAsync({ url: data?.url ?? "manifest_details", data });
  }
);

export const individualCheckout = createAsyncThunk(
  "individual/checkout",
  async (data) => {
    await patchAsync({ url: "customers/individual/check_out", payload: data });
  }
);

export const checkoutAll = createAsyncThunk("all/checkout", async (data) => {
  await patchAsync({ url: "customers/check_out", payload: data });
});
