import { appColor } from 'appConstants'
import React from 'react'
import { BallTriangle } from 'react-loader-spinner'

function PageLoader() {
    return (
        <div style={{ width: '100%', height: '100%', minHeight: "100vh" }} className="df ac jc">
            <BallTriangle
                height={70}
                width={70}
                radius={5}
                color={appColor}
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
            />
        </div>
    )
}

export default PageLoader