import { message } from "antd";
import { CLOUDINARY } from "appConstants";
import axios from "axios";
import { toast } from "react-toastify";
import client, { baseURL } from "./axios";

export const stripBaseUrl = (url) => {
  const urlArr = url.replace(baseURL, "");
  return urlArr;
};
export const postAsync = async ({ url, payload }) => {
  let requestPayload = payload.data;
  if (payload?.setLoader) payload?.setLoader(true);
  console.log(requestPayload);
  try {
    const response = await client.post(url, requestPayload, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    });
    if (response.data.status === "success") {
      if (payload?.silently !== true) {
        toast.success(response.data.message);
      }
      console.log(response.data);
      if (payload.onComplete) payload.onComplete(response.data.data);
      return response.data;
    } else {
      console.log(response);
      return false;
    }
  } catch (err) {
    console.log(err);
    toast.error(err?.message, { toastId: "postError" });
    return false;
  } finally {
    if (payload?.setLoader) payload?.setLoader(false);
  }
};

export const putAsync = async ({ url, payload }) => {
  let requestPayload = payload.data;
  if (payload?.setLoader) payload?.setLoader(true);
  try {
    const response = await client.put(url, requestPayload, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
    if (response.data.status === "success") {
      toast.success(response.data.message);
      if (payload.onComplete) payload.onComplete();
      return true;
    } else {
      console.log(response.data.message);
      return false;
    }
  } catch (err) {
    toast.error(err?.message);
    console.log(err);
    return false;
  } finally {
    if (payload?.setLoader) payload?.setLoader(false);
  }
};

export const patchAsync = async ({ url, payload }) => {
  if (payload?.setLoader) payload?.setLoader(true);
  try {
    const response = await client.patch(url, payload?.data ?? {}, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
    if (response.data.status === "success") {
      if (payload.onComplete) payload.onComplete(response.data.data);
      toast.success(response?.data?.message, { toastId: "patchSuccess" });
      return response.data.data;
    } else {
      console.log(response.data.message);
      return false;
    }
  } catch (err) {
    toast.error(err?.message);
    return false;
  } finally {
    if (payload?.setLoader) payload?.setLoader(false);
  }
};

export const getAsync = async ({ url, data }) => {
  const params = data?.query ?? {};
  console.log(stripBaseUrl(url));
  if (data?.setLoader) data?.setLoader(true);
  try {
    const response = await client.get(stripBaseUrl(url), {
      params,
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
    if (response.data.status === "success") {
      console.log(response.data);
      if (data?.setLoader) data?.setLoader(false);
      if (data?.onComplete)
        data?.onComplete(response?.data?.data, response?.data?.message);
      if (data?.renderMessage)
        message.success({ content: response?.data?.message });
      return response.data.data;
    } else {
      if (data?.setLoader) data?.setLoader(false);
      console.log(response);
      return false;
    }
  } catch (err) {
    if (data?.setLoader) data?.setLoader(false);
    console.log(err.message);
    //if (data?.hideError) toast.error(err.message, { toastId: "getError" });
    return false;
  }
};

export const deleteAsync = async ({ url, payload }) => {
  if (payload?.setLoader) payload?.setLoader(true);
  try {
    const response = await client.delete(url, {
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
    });
    if (response.data.status === "success") {
      toast.success(response.data.message);
      if (payload.onComplete) payload.onComplete();
      return true;
    } else {
      console.log(response.data.message);
      return false;
    }
  } catch (err) {
    toast.error(err?.message);
    console.log(err);
    return false;
  } finally {
    if (payload?.setLoader) payload?.setLoader(false);
  }
};

export const putAsyncForPayment = async ({ url, payload }) => {
  let requestPayload = payload.data;
  if (payload?.setLoader) payload?.setLoader(true);
  try {
    const response = await client.put(
      url,
      {},
      {
        params: requestPayload,
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
      }
    );
    if (response.data.status === "success") {
      toast.success(response.data.message);
      if (payload.onComplete) payload.onComplete();
      return true;
    } else {
      console.log(response.data.message);
      return false;
    }
  } catch (err) {
    toast.error(err?.message);
    console.log(err);
    return false;
  } finally {
    if (payload?.setLoader) payload?.setLoader(false);
  }
};

export const uploadToCloudinary = async ({
  file,
  folder = null,
  iloader = null,
  onComplete = null,
  resourceType = "image",
}) => {
  const cloudinary = CLOUDINARY;
  const uploadUrl = `${cloudinary.BASE_URL}/${cloudinary.NAME}/${resourceType}/upload`;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", cloudinary.UNSIGNED_UPLOAD_PRESET);
  formData.append("folder", folder ?? cloudinary.FOLDERS.IDENTITY_FILES);
  try {
    if (iloader) iloader(true);
    const { data } = await axios.post(uploadUrl, formData, {
      transformRequest: (data, headers) => {
        //delete headers.common["Authorized"];
        return data;
      },
    });
    if (onComplete) onComplete(data);

    return data;
  } catch (ex) {
    toast.error(ex.response?.data?.error?.message ?? "Failed to upload file.");
    console.log(ex);
  } finally {
    if (iloader) iloader(false);
  }
};
