import { createSlice } from "@reduxjs/toolkit";
import { getManifestDetails } from "redux/thunks/roomThunks";
import { getManifest } from "redux/thunks/roomThunks";
import { getRooms } from "redux/thunks/roomThunks";

const RoomSlice = createSlice({
  name: "room",
  initialState: {
    room_list: {},
    check_in: {},
    manifest: {},
    single_manifest: {},
  },
  reducers: {
    setCheckIn: (state, { payload }) => {
      state.check_in = payload;
    },
  },
  extraReducers: {
    [getRooms.fulfilled]: (state, { payload }) => {
      state.room_list = payload;
    },
    ["user/logoutUser"]: (state, { payload }) => {
      state.room_list = {};
    },
    [getManifest.fulfilled]: (state, { payload }) => {
      state.manifest = payload;
    },
    [getManifestDetails.fulfilled]: (state, { payload }) => {
      state.single_manifest = payload;
    },
  },
});

export const { setCheckIn } = RoomSlice.actions;
export default RoomSlice.reducer;
