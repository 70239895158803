import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "redux/thunks/adminThunks";
import { getPreferences } from "redux/thunks/adminThunks";

const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    preferences: [],
    dashboard: {},
  },
  reducers: {
    setPreferences: (state, { payload }) => {
      state.preferences = payload;
    },
  },
  extraReducers: {
    [getPreferences.fulfilled]: (state, { payload }) => {
      state.preferences = payload ?? [];
    },
    [getDashboard.fulfilled]: (state, { payload }) => {
      state.dashboard = payload ?? {};
    },
    ["user/logoutUser"]: (state) => {
      state.preferences = [];
      state.dashboard = {};
    },
  },
});

export const { setPreferences } = AdminSlice.actions;
export default AdminSlice.reducer;
