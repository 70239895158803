import { createAsyncThunk } from "@reduxjs/toolkit";
import { putAsync } from "http/method";
import { postAsync } from "http/method";
import { getAsync } from "http/method";


export const getCategoryList = createAsyncThunk(
    "get/categoryList",
    async (data) => {
      return await getAsync({url:`room_categories/${data?.slug}`,data})
    }
);

export const createCategory = createAsyncThunk(
    "create/category",
    async (data) => {
        await postAsync({url:`room_categories/upload`, payload:data})
    }
);