import { CircularProgress } from '@mui/material';
import { appColor } from 'appConstants';
import React from 'react'
import "styles/siteBtn.css";


function SiteBtn({ font, bg, onClick, type, href, bdc, children, p, icon, bw, clr, height, disabled, rightIcon, width, borderRadius }) {
  const [loader, setLoader] = React.useState(false);
  const getDisabledState = () => {
    if (disabled !== null && typeof disabled !== "undefined") {
      console.log(disabled);
      return disabled;
    }
    return loader;
  }

  const clickHandler = () => {
    if (onClick) onClick(setLoader);
  }
  return (
    <button type={type} href={href} className="siteBtn" onClick={clickHandler} disabled={getDisabledState()} style={{
      borderRadius,
      width, height, color: clr ?? "white", borderWidth: bw ?? "0px", borderColor: bdc, fontSize: font, padding: `${p}px ${p * 2}px`, backgroundColor: bg ?? appColor
    }}>
      {loader ? <> <CircularProgress size='15px' color='inherit' /><span style={{ paddingRight: '5px' }} /></> : <>{icon}<span style={{ paddingRight: '5px' }} /></>} <span style={{ paddingRight: '5px' }}>{children}</span>
      <div style={{ marginLeft: '10px' }}>{rightIcon}</div>
    </button>
  )
}

export default SiteBtn