import { getUsers } from "redux/thunks/adminThunks";

const { createSlice } = require("@reduxjs/toolkit");

const UserSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    data: {},
    reg_form: {},
    users: {},
  },

  reducers: {
    loginUser: (state, { payload }) => {
      state.data = payload;
      state.isLoggedIn = true;
    },
    setRegForm: (state, { payload }) => {
      state.reg_form = payload;
    },
    logoutUser: (state) => {
      state.data = {};
      state.isLoggedIn = false;
    },
    setUserData: (state, { payload }) => {
      state.data = payload;
    },
  },

  extraReducers: {
    [getUsers.fulfilled]: (state, { payload }) => {
      state.users = payload;
    },
  },
});

export const {
  loginUser,
  setUserData,
  logoutUser,
  setRegForm,
} = UserSlice.actions;
export default UserSlice.reducer;
