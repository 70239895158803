import React from 'react'
import TextInput from './textInput'
import { BsLock } from "react-icons/bs";
import { InputAdornment } from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

function PasswordInput({ placeholder, value, errorCheck, onChange, label }) {
  const [view, setView] = React.useState(false);

  return (
    <TextInput placeholder={placeholder} type={view ? 'text' : 'password'} errorCheck={errorCheck} req value={value} label={label} onChange={(e) => onChange(e.target.value)}
      icon={<BsLock size={20} />} endAdornment={<InputAdornment position='end'>
        <small style={{ fontWeight: '600', cursor: 'pointer', color: "grey" }} onClick={() => setView(!view)}>{view ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}</small></InputAdornment>} />
  )
}

export default PasswordInput