import { createSlice } from "@reduxjs/toolkit";
import { getCategoryList } from "redux/thunks/categoriesThunks";

const CategorySlice = createSlice({
    name:'categories',
    initialState:{
        category_list :[]
    },
    reducers:{

    },
    extraReducers:{
        [getCategoryList.fulfilled]:(state , {payload})=> {
                    state.category_list = payload
                },
                ["user/logoutUser"]:(state , {payload})=> {
                    state.category_list = [];
                }
    }
})

export const {} = CategorySlice.actions;
export default CategorySlice.reducer;