import React from "react";
import { useSelector } from "react-redux";
import { FiBarChart2, FiHome, FiSettings, FiUser } from "react-icons/fi";
import { MdSecurity } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";

function useDashboardLinks() {
  const { data = {} } = useSelector((state) => state?.user);

  switch (data?.user?.user_type) {
    case "24":
      return [
        {
          name: "Hotel Records",
          link: "records",
          icon: <FiBarChart2 className="accountListIcon" />,
        },
        {
          name: "Manage admins",
          link: "management",
          icon: <FiUser className="accountListIcon" />,
        },
        {
          name: "Security",
          link: "security",
          icon: <MdSecurity className="accountListIcon" />,
        },
        {
          name: "Messages",
          link: "messages",
          icon: <AiOutlineMessage className="accountListIcon" />,
        },
        {
          name: "Settings",
          link: "settings",
          icon: <FiSettings className="accountListIcon" />,
        },
      ];
    case "11":
      return [
        {
          name: "My hotel list",
          link: "hotel-list",
          icon: <FiHome className="accountListIcon" />,
        },
      ];
    case "12":
      return [
        {
          name: "Hotel list",
          link: "hotels",
          icon: <FiHome className="accountListIcon" />,
        },
        {
          name: "Security",
          link: "security",
          icon: <MdSecurity className="accountListIcon" />,
        },
      ];
    case "1":
      return [
        {
          name: "Hotel",
          link: "home",
          icon: <FiHome className="accountListIcon" />,
        },
      ];
    default:
      return [];
  }
}

export default useDashboardLinks;

export const useDashboardPath = () => {
  const { data = {} } = useSelector((state) => state?.user);

  switch (data?.user?.user_type) {
    case "24":
      return "/admin";
    case "11":
      return "/account";
    case "1":
      return "/staff";
    case "12":
      return "manager";
    default:
      return "/";
  }
};
