import { Box } from '@mui/material'
import { appColor } from 'appConstants'
import Empty from 'components/empty'
import AppLayout from 'components/layouts/appLayout'
import SiteBtn from 'components/siteBtn'
import SiteGap from 'components/siteGap'
import { Title0 } from 'components/typography'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function NoMatchPage() {
    const navigate = useNavigate();


    return (
        <AppLayout>
            <Box sx={{ my: 10 }}>
                <SiteGap>
                    <Empty title={<Title0 style={{ fontWeight: 'bold', textAlign: 'center' }}>Page not found</Title0>}
                        text="Sorry, your current page is not part of this system" />
                    <Box className="df ac jc">
                        <SiteBtn clr={appColor} onClick={() => navigate("/")} bdc={appColor} bw="2px" bg="white" p={5}>Go to homepage</SiteBtn>
                        &nbsp;
                        <SiteBtn clr={appColor} onClick={() => navigate(-1)} bdc={appColor} bw="2px" bg="white" p={5}>Previous page</SiteBtn>
                    </Box>
                </SiteGap>
            </Box>
        </AppLayout>
    )
}

export default NoMatchPage