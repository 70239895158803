import { Container } from '@mui/system'
import Footer from 'components/footer'
import Navbar from 'components/navbar/navbar'
import React from 'react'

function AppLayout({children}) {
  return (
    <div style={{overflow:'hidden'}}>
        <Navbar/>
        <Container sx={{position:'relative'}} disableGutters={true} maxWidth='xl'>
          {children}
        </Container>
        <Footer/>
    </div>
  )
}

export default AppLayout