import { createAsyncThunk } from "@reduxjs/toolkit";
import { postAsync } from "http/method";
import { deleteAsync } from "http/method";
import { patchAsync } from "http/method";
import { getAsync } from "http/method";

export const getPreferences = createAsyncThunk(
  "get/preferences",
  async (data) => {
    return await getAsync({ url: "authority/preferences", data });
  }
);

export const getDashboard = createAsyncThunk("get/dashboard", async (data) => {
  return await getAsync({
    url: data?.url ?? "authority/dashboard",
    data,
  });
});

export const getUsers = createAsyncThunk("get/usersByType", async (data) => {
  return await getAsync({
    url: data?.url ?? `users/${data?.user}`,
    data,
  });
});

export const updatePreferences = createAsyncThunk(
  "update/preferences",
  async (data) => {
    await patchAsync({ url: "authority/preferences", payload: data });
  }
);

export const updateHotelStatus = createAsyncThunk(
  "update/hotelStatus",
  async (data) => {
    await patchAsync({ url: `hotels/${data?.slug}/status`, payload: data });
  }
);

export const updateAdminStatus = createAsyncThunk(
  "update/admin",
  async (data) => {
    await patchAsync({ url: `users/status`, payload: data });
  }
);

export const deleteHotel = createAsyncThunk("delete/hotel", async (data) => {
  await deleteAsync({ url: `hotels/${data?.slug}`, payload: data });
});

export const deleteUser = createAsyncThunk("delete/user", async (data) => {
  await deleteAsync({ url: `user/account/${data?.id}`, payload: data });
});

export const createWanted = createAsyncThunk("create/wanted", async (data) => {
  await postAsync({ url: "wanted_people", payload: data });
});

export const getWantedList = createAsyncThunk("get/wanted", async (data) => {
  return await getAsync({
    url: data?.url ?? `wanted_people`,
    data,
  });
});
export const deleteWanted = createAsyncThunk("delete/wanted", async (data) => {
  await deleteAsync({ url: `wanted_people`, payload: data });
});

export const getUserPermissions = createAsyncThunk(
  "get/userPermissions",
  async (data) => {
    return await getAsync({
      url: data?.url ?? `user/permissions`,
      data,
    });
  }
);

export const updateUserPermissions = createAsyncThunk(
  "update/userPermissions",
  async (data) => {
    await patchAsync({ url: `user/permissions`, payload: data });
  }
);
