import { Grid, InputBase, Paper } from "@mui/material";
import React from "react";
import { Red } from "../typography";

function EmailInput(props) {
  const {
    width,
    id,
    name,
    height,
    inputRef,
    placeholder,
    errorCheck,
    label,
    req,
    padding,
    bd,
    icon,
    readOnly,
    onChange,
    endAdornment,
    value,
    disabled,
  } = props;
    const [emailValue, setEmail] = React.useState(value);
  const [emailError, setEmailError] = React.useState(errorCheck);

    React.useEffect(() => {
      setEmailError(errorCheck);
    }, [errorCheck]);

  function validateEmail(email) {
    var reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (reg.test(email)) {
      return true;
    }
    return false;
  }

  const validateAndReturnValue = (e) => {
    setEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      onChange(e);
      setEmailError(false);
    } else {
      onChange("");
      setEmailError(true);
    }
  };
      return (
        <>
          {label ? (
            <label style={{ fontWeight: "600", fontSize: "0.85em" }}>
              {label}
              <span style={{ color: "red" }}>{req ? " *" : ""}</span>
            </label>
          ) : (
            <></>
          )}
          <Paper
            elevation={0}
            style={{
              borderRadius: "7px",
              border: `${bd ?? 1}px solid rgb(230,230,230)`,
              width,
              height,
              padding: padding ?? "8px",
            }}
          >
            <Grid
              container
              justifyContent="space-around"
              alignItems="center"
              style={{ width: "100%" }}
            >
              {icon ? (
                <Grid style={{ color: "grey" }} item xs={1} lg={1}>
                  {icon}
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={11} lg={11}>
                <InputBase
                  inputRef={inputRef}
                  readOnly={readOnly ?? false}
                  className="inputBase"
                  disabled={disabled ?? false}
                  required={req}
                  type="email"
                  id={id}
                  name={name}
                  style={{
                    paddingLeft: "5px",
                    fontSize: "15px",
                    border: "none",
                    width: "100%",
                  }}
                  value={emailValue ?? ""}
                  onChange={(e) => validateAndReturnValue(e)}
                  placeholder={placeholder}
                  endAdornment={endAdornment}
                />
              </Grid>
            </Grid>
          </Paper>
          {emailError && !emailValue ? (
            <Red sm>The {label} field is required</Red>):<></>}
          {emailError && !validateEmail(emailValue) ? (
            <Red sm>Please enter a valid email address</Red>
          ) : (
            <></>
          )}
        </>
      );
}


export default EmailInput;
