import { createSlice } from "@reduxjs/toolkit";
import { getWantedList } from "redux/thunks/adminThunks";

const DistressSlice = createSlice({
  name: "distress",
  initialState: {
    wanted_list: [],
    messages: [],
  },
  reducers: {},
  extraReducers: {
    [getWantedList.fulfilled]: (state, { payload }) => {
      state.wanted_list = payload;
    },
  },
});

export const {} = DistressSlice.actions;
export default DistressSlice.reducer;
