import { brandShort } from "appConstants";
import axios from "axios";
import Cookies from "js-cookie";
export const baseURL = "https://api.smartabuja.com/api/v1/";
const token = Cookies.get(`${brandShort}_token`);

const client = axios.create({ baseURL });
client.interceptors.request.use((configs) => {
  // configs.headers["Bypass-Tunnel-Reminder"] = "ifeanyi";
  // configs.headers["No-Redirect"] = true;
  configs.headers["X-Requested-With"] = "XMLHttpRequest";
  configs.headers["Accept"] = "application/json";
  configs.headers["content-type"] = "application/json";
  configs.headers["Authorization"] = `Bearer ${token}`;
  return configs;
});

client.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      if (err.response.status === 500) {
        console.log(err);
        throw new Error("An error occurred, please contact administrator");
      }
      if (err.response.status === 401) {
        console.log(err.response.data.message);
        throw new Error("Please log in before you proceed");
      }
      if (err.response.status === 402) {
        console.log(err.response.data.message);
        throw new Error(err.response.data.message);
      }
      if (err.response.status === 422) {
        const errors = err?.response?.data?.errors ?? {};
        console.log({ errors });
        if (Object.keys(errors).length > 0) {
          let list = Object.values(errors);
          list.map((item) => {
            throw new Error(item.toString());
          });
        }
        // console.log(err.response.data.message);
        // throw new Error(err.response.data.message);
      }
      if (err.response.status === 405) {
        //console.log(err.response.data.message);
        throw new Error(err.response.data.message);
      }
      if (err.response.status === 403) {
        console.log(err);
        throw new Error(err.response.data.message);
      }
      console.log(err);
      throw new Error("Network error");
    }
  }
);
export default client;
