import PasswordInput from "components/inputs/passwordInput";
import TextInput from "components/inputs/textInput";
import AuthLayout from "components/layouts/authLayout";
import { AiOutlineMail } from "react-icons/ai";
import React from "react";
import { Modal } from "antd";
import { Span } from "components/typography";
import { appColor } from "appConstants";
import SiteBtn from "components/siteBtn";
import { useDispatch, useSelector } from "react-redux";
// import { loginStaff } from 'redux/thunks/authThunks';
// import { setUserData } from 'redux/slice/userSlice';
// import ReDirector from 'components/redirector';
import { useNavigate } from "react-router-dom";
// import { P } from 'components/typography';
import { Grey } from "components/typography";
import { Title3 } from "components/typography";
import { toast } from "react-toastify";
import { loginUser } from "redux/slice/userSlice";
import EmailInput from "components/inputs/emailInput";
import { login } from "redux/thunks/authThunks";
import { setUserData } from "redux/slice/userSlice";
import Cookies from "js-cookie";
import { brandShort } from "appConstants";
// import { initPasswordReset } from 'redux/thunks/userThunks';
// import { setVerificationForm } from 'redux/slice/userSlice';

function Login() {
  const [formState, setForm] = React.useState({});
  //const { current_sub_school = {} } = useSelector((state) => state.app)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = React.useState(false);

  const handle = (value, key) => {
    setForm({ ...formState, [key]: value });
  };
  const submit = (setLoader) => {
    if (formState?.email && formState?.password) {
      dispatch(
        login({
          data: { email: formState?.email, password: formState?.password },
          setLoader,
          onComplete: (data) => {
            console.log({ data });
            dispatch(setUserData(data));
            Cookies.set(`${brandShort}_token`, data?.token);
            switch (data?.user?.user_type) {
              case "11":
                window.location.assign("/account");
                break;
              case "24":
                window.location.assign("/admin");
                break;
              case "12":
                window.location.assign("/manager");
                break;
              default:
                break;
            }
          },
        })
      );
    } else {
      setError(true);
    }
  };

  return (
    <AuthLayout
      title="Sign in"
      onSubmit={submit}
      counterLink="/register"
      counter="register"
    >
      <p>
        <EmailInput
          req
          errorCheck={error}
          placeholder="Enter your email"
          value={formState?.email ?? ""}
          label="Email"
          onChange={(e) => handle(e.target.value, "email")}
          icon={<AiOutlineMail size={20} />}
        />
      </p>
      <p>
        <PasswordInput
          errorCheck={error}
          value={formState?.password ?? ""}
          placeholder="Enter your password"
          label="Password"
          onChange={(e) => handle(e, "password")}
        />
      </p>
      {/* <p>
        <ForgotPasswordModal mail={formState?.email} from="/staff-login" />
      </p> */}
    </AuthLayout>
  );
}
export default Login;

export const ForgotPasswordModal = ({ mail, onSubmit, from }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [email, setEmail] = React.useState(mail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    setEmail(mail);
  }, [mail]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const submit = (setLoader) => {
    // dispatch(initPasswordReset({
    //     data: { email }, setLoader, onComplete: () => {
    //         dispatch(setVerificationForm({ email, from }));
    //         navigate("/reset-password");
    //     }
    // }))
    setIsModalVisible(false);
  };

  return (
    <>
      <Span onClick={showModal} style={{ cursor: "pointer", color: appColor }}>
        Forgot password
      </Span>
      <Modal
        title={
          <div style={{ textAlign: "center" }}>
            <Title3>Forgot password</Title3>
            <Grey>
              Enter the email address for your account. You will receive a
              verification code to reset your password with.
            </Grey>
          </div>
        }
        footer={null}
        bodyStyle={{ padding: "4vh" }}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          <TextInput
            placeholder="Enter your email"
            type="email"
            value={email ?? ""}
            label="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            icon={<AiOutlineMail size={20} />}
          />
        </p>
        <p>
          <SiteBtn p={10} width="100%" onClick={submit}>
            Submit email
          </SiteBtn>
        </p>
      </Modal>
    </>
  );
};
