import { Grid } from '@mui/material'
import React from 'react'
import GItem from './grids'
import { empty } from "appConstants";
import { appColor } from 'appConstants';
import { Grey, P } from './typography';


function Empty({ title, text }) {
    return (
        <div style={{ height: 'inherit', padding: '20px 0px' }} className="df ac">
            <Grid container className="jc ac" sx={{ py: "5vh" }} spacing={2}>
                <GItem sizes={[7, 6, 5, 3]}>
                    <img src={empty} style={{ width: '100%', height: 'auto', borderRadius: '50%', border: `1px dotted ${appColor}` }} alt="empty content" />
                </GItem>
                <GItem sizes={[11, 10, 9, 10]} sx={{ textAlign: 'center' }}>
                    {title ? <P style={{ fontWeight: 'bold' }}>{title ?? "No content found"}</P> : <></>}
                    {text ? <Grey>{text}</Grey> : <></>}
                </GItem>
            </Grid>
        </div>
    )
}

export default Empty