import { appLogo, brandLong, brandShort } from "./../appConstants";
import { Span } from "components/typography";
import { appColor } from "appConstants";
import React from "react";
// import { useSelector } from 'react-redux'
import { P, Title0 } from "./typography";

function Brand({ color }) {
  //   const { current_sub_school = {} } = useSelector((state) => state.app)
  return (
    <div className="df ac">
      <img src={appLogo} style={{ width: "50px", height: "50px" }} />
      <div style={{ marginLeft: "10px" }}>
        <P
          className="title0"
          style={{ fontSize: "33px", fontWeight: "bold" }}
          color={color ?? appColor}
        >
          {brandShort}
        </P>
        <P sm className="ellipsis" style={{ width: "210px", color: appColor }}>
          {" "}
          {brandLong}
        </P>
      </div>
    </div>
  );
}

export default Brand;
