import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { appDark } from "appConstants";
import "styles/layout.css";
import { MdOutlineFacebook } from "react-icons/md";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { facebookUrl, instagramUrl, twitterUrl } from "appConstants";
import { Link, useNavigate } from "react-router-dom";
import { Grey, P, Title0, Title1, Title2 } from "./typography";
import Brand from "./brand";
import { IoIosHeart } from "react-icons/io";
import SiteBtn from "./siteBtn";
import GItem from "./grids";
import Dots from "./dots";
import { useDashboardPath } from "hooks/useDashboardLinks";

function Footer() {
  const navigate = useNavigate();
  const path = useDashboardPath();
  return (
    <>
      <Container disableGutters={true} maxWidth="xl">
        <div className="footerContainer1">
          <div style={{ height: "inherit" }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{
                backgroundColor: "rgb(230,230,230)",
                padding: "50px 4vw 50px 4vw",
              }}
            >
              <Grid item xs={12} sm={7} lg={5} md={6}>
                <Title2 color="black">Register your hotel business?</Title2>
                <P color="black">
                  You can create and monitor your hotel business in this
                  platform, sign up to get started and create your hotel
                </P>
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5} className="footerBtn">
                <br />
                <SiteBtn p={20} onClick={() => navigate("/login")}>
                  Login | Register
                </SiteBtn>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                backgroundColor: "rgb(230,230,230)",
                padding: "20px 4vw 20px 4vw",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column",
                      marginBottom: "20px",
                    }}
                  >
                    <Title1 style={{ fontWeight: "bold", color: "black" }}>
                      Useful links
                    </Title1>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9}>
                <Grid
                  spacing={2}
                  container
                  justifyContent="flex-end"
                  className="footerLinks"
                >
                  <Grid item xs={7} md={3} lg={3}>
                    <Link to="/register">Register</Link>
                    <Link to="/login">Login</Link>
                  </Grid>
                  <Grid item xs={5} md={2} lg={3}>
                    {/* <h4 style={{ color: 'white' }}>Social Medias</h4> */}
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      rel="noreferrer"
                      target="_blank"
                      href={facebookUrl}
                    >
                      {" "}
                      <MdOutlineFacebook
                        size={20}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Facebook
                    </a>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      rel="noreferrer"
                      target="_blank"
                      href={twitterUrl}
                    >
                      {" "}
                      <AiOutlineTwitter
                        size={20}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Twitter
                    </a>
                    <a
                      style={{ display: "flex", alignItems: "center" }}
                      rel="noreferrer"
                      target="_blank"
                      href={instagramUrl}
                    >
                      {" "}
                      <AiFillInstagram
                        size={20}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Instagram
                    </a>
                  </Grid>
                </Grid>
              </Grid>
              <GItem sx={{ mt: 2, textAlign: "center !important" }}>
                <Box sx={{ my: 2 }} className="df jc">
                  <Dots />
                </Box>
                <div className="df jc">
                  <Brand color="black" />
                </div>
              </GItem>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{
                background: "rgb(230,230,230)",
                padding: "20px 4vw 0px 4vw",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: "25px",
                  height: "1px",
                  backgroundColor: "rgb(150,150,150)",
                }}
              />
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: "center" }}
              >
                <P color="black">
                  Copyright ©{new Date().getFullYear()}{" "}
                  <a
                    href="https://capitaltelecoms.com"
                    target="_blank"
                    style={{ color: "black" }}
                  >
                    Mediatek Concept
                  </a>
                </P>
                <br />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Footer;
