import { Grid } from '@mui/material'
import { appLink, appColor } from 'appConstants'
import GItem from 'components/grids'
import SiteBtn from 'components/siteBtn'
import SiteGap from 'components/siteGap'
import SiteLink from 'components/siteLink'
import { Span } from 'components/typography'
import { Title1 } from 'components/typography'
import React from 'react'
import AppLayout from './appLayout'

function AuthLayout({ title, counterLink, counter, children, onSubmit, hideSubmit, sizes }) {
  return (
    <AppLayout>
      <SiteGap>
        <Grid container className='ac jc' sx={{ my: 15 }}>
          <GItem sizes={sizes ?? [12, 8, 6, 4]}>
            <Title1 color={appColor}>{title}</Title1>
            {counterLink ? <Span>{counter === "register" ? "You don't have an account yet?" : "You already have an account?"}</Span> : <></>}
            {counterLink ? <SiteLink color={appLink} to={counterLink}>{counter === "register" ? "Register now" : "Login"}</SiteLink> : <></>}

            {children}
            <br />
            {hideSubmit ? <></> : <SiteBtn onClick={onSubmit} width="100%" p={10}>Submit</SiteBtn>}
          </GItem>
        </Grid>
      </SiteGap>
    </AppLayout>
  )
}

export default AuthLayout