import { getHotelStaffs } from "redux/thunks/hotelThunks";
import { getSingleHotel } from "redux/thunks/hotelThunks";
import { getHotels } from "redux/thunks/hotelThunks";

const { createSlice } = require("@reduxjs/toolkit");

const HotelSlice = createSlice({
  name: "hotel",
  initialState: {
    hotel_list: {},
    single_hotel: {},
    hotel_staffs: {},
    show_images: false,
  },
  reducers: {
    setHotelList: (state, { payload }) => {
      state.hotel_list = payload;
    },
    setSingleHotel: (state, { payload }) => {
      state.single_hotel = payload;
    },
    setShowImages: (state, { payload }) => {
      state.show_images = payload;
    },
  },
  extraReducers: {
    [getHotels.fulfilled]: (state, { payload }) => {
      state.hotel_list = payload;
    },
    [getHotelStaffs.fulfilled]: (state, { payload }) => {
      state.hotel_staffs = payload;
    },
    [getSingleHotel.fulfilled]: (state, { payload }) => {
      state.single_hotel = payload ? payload : state.single_hotel;
    },
    ["user/logoutUser"]: (state, { payload }) => {
      state.hotel_list = {};
      state.single_hotel = {};
    },
  },
});

export const {
  setHotelList,
  setShowImages,
  setSingleHotel,
} = HotelSlice.actions;
export default HotelSlice.reducer;
