import { configureStore, combineReducers } from "@reduxjs/toolkit";
//import { useMemo } from 'react';
import {
  persistReducer,
  //   FLUSH,
  //   REHYDRATE,
  //   PAUSE,
  //   PERSIST,
  //   PURGE,
  //   REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminSlice from "./slice/adminSlice";
import categorySlice from "./slice/categorySlice";
import distressSlice from "./slice/distressSlice";
import hotelSlice from "./slice/hotelSlice";
import roomSlice from "./slice/roomSlice";
import userSlice from "./slice/userSlice";

// init store
//let store;

const persistConfig = {
  key: "hoteller",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: userSlice,
    hotel: hotelSlice,
    categories: categorySlice,
    room: roomSlice,
    admin: adminSlice,
    distress: distressSlice,
  })
);

// function makeStore(
//   initialState = {

//     }
// ) {
//   return configureStore({
//     reducer: persistedReducer,
//     middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware({
//         serializableCheck: {
//           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         },
//       }),
//     initialState,
//   });
export const store = configureStore({
  reducer: persistedReducer,
});

// export const initializeStore = (preloadedState) => {
//   let _store = store ?? makeStore(preloadedState);

//   // after navigating to a page with an initial Redux state, merge that state with the current state in the store and create a new store
//   if (preloadedState && store) {
//     _store = makeStore({
//       ...store.getState(),
//       ...preloadedState,
//     });

//     // reset current state
//     store = undefined;
//   }

//   // for SSG and SSR - create new store
//   //if (typeof windows === 'undefined') return _store;

//   // create store once for client
//   if (!store) store = _store;
// };

// export const useStore = (initialState) => {
//   const store = useMemo(() => initializeStore(initialState), [initialState]);
//   return store;
// };
