import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteAsync } from "http/method";
import { postAsync } from "http/method";

export const register = createAsyncThunk("user/register", async (data) => {
  return await postAsync({ url: "user/register", payload: data });
});

export const login = createAsyncThunk("user/login", async (data) => {
  return await postAsync({ url: "user/login", payload: data });
});

export const logout = createAsyncThunk("user/logout", async (data) => {
  return await deleteAsync({ url: "user/logout", payload: data });
});
