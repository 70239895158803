import { Grid, InputBase, Paper } from '@mui/material'
import { Grey } from 'components/typography';
import { Red } from 'components/typography';
import React from 'react'

function TextInput({ width, helper, type, height, placeholder, errorCheck, label, req, padding, bd, icon, readOnly, onChange, endAdornment, value, multiline, rows, disabled }) {
    const [error, setError] = React.useState(errorCheck);

    React.useEffect(() => {
        setError(errorCheck);
    }, [errorCheck, value])


    return (
        <>
            {label ? <label style={{ fontWeight: '600', fontSize: '0.85em' }}>{label}<span style={{ color: 'red' }}>{req ? ' *' : ''}</span></label> : <></>}
            <Paper elevation={0} style={{ borderRadius: "7px", border: `${bd ?? 1}px solid rgb(230,230,230)`, width, height, padding: padding ?? "8px" }}>
                <Grid container justifyContent='space-around' alignItems='center' style={{ width: '100%' }}>
                    {icon ? <Grid style={{ color: 'grey' }} item xs={1} lg={1}>
                        {icon}
                    </Grid> : <></>}
                    <Grid item xs={11} lg={11}>
                        <InputBase readOnly={readOnly ?? false} className='inputBase' disabled={disabled ?? false} required={req} type={type} multiline={multiline ?? false} minRows={rows}
                            style={{ paddingLeft: '5px', fontSize: '15px', border: "none", width: '100%' }} value={value}
                            onChange={onChange} placeholder={placeholder} endAdornment={endAdornment} />
                    </Grid>
                </Grid>
            </Paper>
            {error && !value ? <Red sm>The {label} field is required</Red> : <></>}
            {helper ? <Grey sm>{helper}</Grey> : <></>}
        </>
    )
}


export default TextInput